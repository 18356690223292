const GirlMedidationPose = (props: {
  className?: string;
}) => {

  return <svg
    className={props.className ?? ""}
    xmlns="http://www.w3.org/2000/svg"
    width={74}
    height={74}
    viewBox="0 0 74 74"
    fill="none"
  >
    <circle cx="39.1553" cy="17.9762" r="16.319" fill="#8BC1FF" />
    <path
      d="M45.249 34.0812L42.1914 33.2552C41.1202 27.2239 41.3182 25.8975 41.3182 25.8975L37.5399 26.5843L33.8 25.7129C33.8 25.7129 34.3913 27.3817 32.8313 32.9743L29.656 33.6646C29.656 33.6646 29.276 54.6781 36.3438 55.0054L36.6114 55.0117C43.6864 55.0313 45.2482 34.0803 45.2482 34.0803L45.249 34.0812Z"
      fill="#E58366"
    />
    <path
      d="M39.2447 30.9048C40.3035 31.0538 41.1954 31.0627 41.8216 31.0404C41.636 28.0203 41.446 26.5093 41.2525 26.5093C41.2231 26.5093 41.1936 26.5441 41.1642 26.6128L37.3859 27.2996L33.6459 26.4281C33.6834 26.3042 33.7084 26.2435 33.7218 26.2462C33.7557 26.2533 33.7235 26.2703 33.5817 27.1693C34.0919 28.0506 34.6021 28.9372 35.4067 29.5883C36.5332 30.4999 37.8524 30.7095 39.2438 30.9048H39.2447Z"
      fill="#C96545"
    />
    <path
      d="M7.09985 27.2643C7.09985 27.2643 6.69313 25.9585 6.82513 25.6454C6.95625 25.3324 7.84463 24.3138 7.95167 23.9498C8.0587 23.5859 7.50569 22.7564 6.51652 23.8241C5.52735 24.8917 5.61654 24.8177 5.87878 27.193"
      fill="#D8693A"
    />
    <path
      d="M7.53144 33.4639C7.53144 33.4639 4.09476 29.8104 3.47396 28.5135C2.85317 27.2167 3.04226 21.7338 4.08851 21.3735C5.13477 21.0131 5.00544 26.3265 5.00544 26.3265C5.00544 26.3265 7.16931 26.1044 8.46264 27.9231C8.46264 27.9231 9.08611 26.6235 8.87383 25.7878C8.87383 25.7878 7.36911 24.2108 7.21212 23.3946C7.05603 22.5785 9.18779 23.9958 9.94595 24.993C10.7041 25.9893 10.3224 30.6096 10.3224 30.6096L7.64472 33.4086"
      fill="#E58366"
    />
    <path
      d="M4.84514 26.8977C4.62126 25.112 4.82106 23.2755 5.42134 21.579C5.47753 21.4202 5.56405 21.2391 5.72996 21.2106C5.9913 21.1651 6.12331 21.513 6.14828 21.777C6.33916 23.7732 6.31329 25.7899 6.06979 27.7798"
      fill="#E58366"
    />
    <path
      d="M3.94042 29.2286C2.61231 28.0146 1.57319 26.4867 0.930985 24.8063C0.871224 24.6493 0.81949 24.4548 0.927416 24.3255C1.09689 24.1213 1.42245 24.3005 1.61333 24.4861C3.05293 25.8811 4.34091 27.434 5.44603 29.1064"
      fill="#E58366"
    />
    <path
      d="M9.02275 29.5589C9.00491 29.5544 8.98886 29.5446 8.97726 29.5295C7.36283 27.4173 5.39073 28.1951 5.37111 28.2031C5.33008 28.2201 5.2837 28.2005 5.26586 28.1594C5.24891 28.1184 5.26853 28.0711 5.30956 28.0542C5.33008 28.0461 7.41635 27.2229 9.10481 29.4313C9.13157 29.467 9.12533 29.517 9.08965 29.5446C9.07003 29.5598 9.04505 29.5642 9.02186 29.5589H9.02275Z"
      fill="#430B12"
    />
    <path
      d="M5.05622 27.6055C5.02233 27.5974 4.99646 27.568 4.99468 27.5314L4.80469 23.5614C4.80291 23.5168 4.83681 23.4793 4.8814 23.4766C4.926 23.4748 4.96346 23.5087 4.96525 23.5533L5.15523 27.5234C5.15701 27.568 5.12312 27.6055 5.07852 27.6081C5.0705 27.6081 5.06336 27.6081 5.05622 27.6055Z"
      fill="#430B12"
    />
    <path
      d="M4.51521 28.6284C4.50896 28.6266 4.50183 28.6248 4.49647 28.6212C4.45723 28.6007 4.44207 28.5517 4.46347 28.5124C4.50361 28.4366 5.458 26.6616 6.17958 26.4779C6.2224 26.4663 6.2661 26.4921 6.2777 26.5358C6.2884 26.5787 6.26254 26.6224 6.21972 26.634C5.56592 26.8008 4.6151 28.5704 4.60529 28.5882C4.58745 28.6212 4.54999 28.6373 4.51521 28.6284Z"
      fill="#430B12"
    />
    <path
      d="M4.63411 28.3636C4.6127 28.3583 4.59308 28.3449 4.58238 28.3235C4.02312 27.2987 3.00362 26.1677 2.99292 26.1561C2.96349 26.1231 2.96527 26.0722 2.99827 26.0428C3.03127 26.0134 3.08212 26.0151 3.11244 26.0481C3.12315 26.0597 4.15602 27.205 4.7233 28.2468C4.74471 28.286 4.73044 28.3351 4.69119 28.3556C4.67335 28.3654 4.65284 28.3681 4.63411 28.3636Z"
      fill="#430B12"
    />
    <path
      d="M29.3105 33.6647C29.3105 33.6647 24.4806 33.5925 20.6363 37.7275C16.7911 41.8626 15.818 42.314 15.818 42.314C15.818 42.314 12.0903 34.2586 10.3638 30.4526L6.35094 34.2586C6.35094 34.2586 5.64973 44.4439 11.2574 49.0045C16.8651 53.565 27.2349 46.466 27.2349 46.466L29.7261 43.2835L29.3114 33.6656L29.3105 33.6647Z"
      fill="#0EB891"
    />
    <path
      d="M66.4672 27.8551C66.4672 27.8551 66.9696 26.5831 66.8612 26.2611C66.7536 25.9391 65.9432 24.8575 65.8635 24.4866C65.7837 24.1158 66.3967 23.3295 67.304 24.4676C68.2113 25.6057 68.1278 25.5253 67.6902 27.8745"
      fill="#D8693A"
    />
    <path
      d="M65.5765 34.0045C65.5765 34.0045 69.2747 30.6159 69.9899 29.3687C70.7052 28.1214 70.9232 22.6396 69.9065 22.2027C68.8898 21.7657 68.6248 27.0741 68.6248 27.0741C68.6248 27.0741 66.4834 26.6921 65.0588 28.4099C65.0588 28.4099 64.5334 27.0677 64.807 26.25C64.807 26.25 66.4245 24.7889 66.6416 23.9867C66.8578 23.1843 64.6268 24.4397 63.7968 25.3779C62.9668 26.3153 63.0049 30.9511 63.0049 30.9511L65.4677 33.9409"
      fill="#E58366"
    />
    <path
      d="M68.7415 27.655C69.0971 25.8908 69.0341 24.0445 68.5613 22.3082C68.517 22.1457 68.4441 21.9587 68.2808 21.9179C68.0236 21.8532 67.8661 22.1903 67.8216 22.4517C67.4833 24.4283 67.3595 26.4413 67.4548 28.4439"
      fill="#E58366"
    />
    <path
      d="M69.4739 30.0472C70.8884 28.935 72.038 27.4884 72.803 25.8602C72.8743 25.7081 72.9403 25.518 72.8422 25.381C72.6884 25.1648 72.3504 25.3194 72.1463 25.4903C70.6072 26.7747 69.2076 28.2278 67.9815 29.8137"
      fill="#E58366"
    />
    <path
      d="M64.3816 30.0001C64.3997 29.997 64.4165 29.9884 64.4291 29.9741C66.1957 27.9875 68.1047 28.9094 68.1237 28.9189C68.1634 28.9388 68.2111 28.9227 68.2319 28.8831C68.2519 28.8434 68.2358 28.7948 68.1961 28.7749C68.1763 28.7653 66.1568 27.7896 64.3092 29.8668C64.2799 29.9004 64.2824 29.9507 64.3159 29.9809C64.3344 29.9975 64.359 30.0038 64.3825 30.0002L64.3816 30.0001Z"
      fill="#430B12"
    />
    <path
      d="M68.4794 28.3456C68.5138 28.3401 68.5418 28.3127 68.5463 28.2763L69.0301 24.3313C69.0352 24.2869 69.0042 24.2471 68.9599 24.2411C68.9156 24.236 68.8757 24.267 68.8706 24.3114L68.3868 28.2564C68.3817 28.3008 68.4127 28.3406 68.457 28.3466C68.465 28.3472 68.4721 28.3477 68.4794 28.3456Z"
      fill="#430B12"
    />
    <path
      d="M68.9443 29.4052C68.9507 29.4038 68.9579 29.4026 68.9635 29.3994C69.0042 29.3819 69.0229 29.3341 69.0045 29.2934C68.9701 29.2148 68.15 27.3739 67.444 27.1372C67.4021 27.1224 67.3566 27.145 67.3418 27.1877C67.328 27.2296 67.3505 27.2751 67.3924 27.2899C68.032 27.5047 68.849 29.3399 68.8575 29.3585C68.8728 29.3927 68.909 29.4115 68.9443 29.4052Z"
      fill="#430B12"
    />
    <path
      d="M68.8499 29.1331C68.8717 29.1294 68.8922 29.1175 68.9045 29.0969C69.5382 28.1163 70.6387 27.0641 70.6503 27.0533C70.6821 27.0226 70.6841 26.9717 70.6533 26.9399C70.6226 26.9081 70.5718 26.9061 70.5391 26.9368C70.5275 26.9476 69.4126 28.0131 68.7696 29.01C68.7454 29.0475 68.756 29.0975 68.7936 29.1209C68.8106 29.132 68.8309 29.1362 68.8499 29.1331Z"
      fill="#430B12"
    />
    <path
      d="M45.3752 33.6658C45.3752 33.6658 50.2052 33.5936 54.0495 37.7287C57.8947 41.8638 58.8678 42.3151 58.8678 42.3151C58.8678 42.3151 60.6316 36.4535 62.9869 30.1631L66.9998 33.969C66.9998 33.969 69.036 44.4451 63.4283 49.0056C57.8206 53.5661 47.4508 46.4671 47.4508 46.4671L44.9596 43.2846L45.3744 33.6667L45.3752 33.6658Z"
      fill="#0EB891"
    />
    <path
      d="M51.9938 45.1055C52.1089 45.9519 52.1758 46.7904 52.1758 47.5985C52.1758 49.7128 52.0272 53.2746 51.8802 56.2801C51.716 59.638 49.8987 62.738 46.6836 63.7204C42.6696 64.9469 36.5096 65.8305 29.3957 63.4136C26.3938 62.3938 24.5733 59.4743 24.3223 56.3138L23.7046 48.5332C23.649 47.8319 23.6514 47.1272 23.712 46.4263L24.7252 34.7005C26.196 34.4704 27.2182 33.2671 29.9752 33.2573C32.1783 34.9386 34.9701 35.4961 37.514 35.5345C40.0284 35.5719 42.1093 34.8816 43.92 33.7354C44.7727 33.7854 47.7313 33.9611 48.6107 34.0538"
      fill="#0266DA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.5267 9.16176C37.5267 9.16176 33.471 7.19233 31.3214 11.1107C29.1727 15.0299 26.9411 19.6903 27.2532 22.6668C27.5654 25.6432 32.3472 29.4028 37.1236 26.5343C37.1236 26.5343 40.5977 27.8089 44.7016 27.213C48.8063 26.6163 49.6448 24.0823 49.0793 19.4005C48.6386 12.0089 48.5031 14.4243 45.6069 11.1178C42.5903 7.67488 38.9726 8.26535 37.5258 9.16176H37.5267Z"
      fill="#343434"
    />
    <path
      d="M29.9279 20.0185C30.3605 21.0924 31.1436 21.6374 32.3317 21.8942C33.1978 22.0816 34.0924 22.1306 34.9755 22.2225C35.846 22.3135 36.7032 22.4749 37.5701 22.5864C38.9063 22.7594 40.2594 22.8558 41.6089 22.9137C43.3785 22.9896 46.5182 23.102 47.1095 21.0415C47.1425 20.9265 47.0801 20.8364 46.9882 20.7891C47.9747 19.461 48.5304 17.7931 48.7784 16.2429C49.0584 14.4848 48.8694 12.808 47.9926 11.2354C47.1033 9.63975 45.7422 8.20014 44.1331 7.20562C42.6926 6.31634 41.319 6.52773 40.0578 7.46963C39.5422 6.57679 38.1838 6.2102 37.1625 6.20128C35.5757 6.1879 34.286 7.37776 33.3423 8.44989C31.4638 10.5861 29.9306 13.0336 29.3464 15.755C29.0369 17.1963 29.3856 18.6698 29.9288 20.0176L29.9279 20.0185Z"
      fill="#343434"
    />
    <path
      d="M45.7384 20.4078C45.7345 20.4186 45.764 20.3576 45.7384 20.4078V20.4078Z"
      fill="#F6A383"
    />
    <path
      d="M46.0954 22.5484C46.0883 22.5484 46.0812 22.5484 46.0749 22.5484C45.9795 22.5404 45.9108 22.461 45.9215 22.3727C45.9269 22.3255 46.0749 21.2034 47.5199 20.6156C47.6082 20.579 47.7099 20.6165 47.7464 20.6985C47.7839 20.7797 47.742 20.8751 47.6537 20.9117C46.3942 21.4237 46.2667 22.3932 46.2658 22.4031C46.256 22.4851 46.182 22.5476 46.0954 22.5493V22.5484Z"
      fill="#D67E63"
    />
    <path
      d="M46.1771 22.2477C46.137 22.2486 46.0959 22.237 46.0629 22.2111C45.9898 22.1541 45.9818 22.0524 46.0433 21.9837C46.5348 21.4405 47.4053 21.511 47.4419 21.5136C47.5373 21.5226 47.606 21.601 47.5953 21.6893C47.5846 21.7777 47.4999 21.8445 47.4044 21.8347C47.3973 21.8338 46.6784 21.7785 46.3073 22.1888C46.2734 22.2263 46.2253 22.2459 46.1771 22.2477Z"
      fill="#D67E63"
    />
    <path
      d="M31.6692 21.8176C31.5818 21.8194 31.506 21.7596 31.4953 21.6775C31.357 20.5742 30.854 20.1157 30.8486 20.1113C30.78 20.0506 30.7773 19.9489 30.8433 19.8829C30.9093 19.8178 31.0181 19.8134 31.0868 19.8731C31.1109 19.8945 31.6853 20.4038 31.8387 21.6338C31.8494 21.7221 31.7816 21.8033 31.6862 21.8149C31.6799 21.8149 31.6737 21.8158 31.6674 21.8167L31.6692 21.8176Z"
      fill="#D67E63"
    />
    <path
      d="M31.6278 21.6366C31.5555 21.6383 31.4878 21.5973 31.4628 21.5313C31.2719 21.0291 30.7412 21.1014 30.7189 21.1041C30.6252 21.1183 30.536 21.0577 30.5227 20.9703C30.5093 20.882 30.5753 20.799 30.6698 20.7856C30.9579 20.7428 31.5627 20.8213 31.7892 21.4198C31.8213 21.5037 31.7732 21.5964 31.6831 21.6276C31.6644 21.6339 31.6456 21.6375 31.6278 21.6375V21.6366Z"
      fill="#D67E63"
    />
    <path
      d="M41.8088 27.4559C41.9195 27.3711 42.0265 27.2837 42.1317 27.1918C42.6803 26.7111 43.0603 26.1269 43.5098 25.5623C43.8737 25.1056 44.268 24.681 44.6051 24.2029C44.7576 23.9862 45.0368 23.6214 45.2036 23.3333C45.2625 23.2325 45.3668 23.1638 45.4881 23.1477C45.564 23.1379 45.6398 23.1263 45.7156 23.1139C45.7343 23.1112 45.7522 23.1067 45.77 23.1005C45.8788 23.0666 45.9894 23.0389 46.0929 23.0095C46.5264 22.8864 48.0507 22.1068 48.3174 21.0642C48.5395 20.1954 48.5422 19.0564 47.7698 18.5248C46.9501 17.9611 45.6148 18.019 45.2268 19.035C45.2232 19.0448 45.2509 18.9895 45.2268 19.035C44.8861 17.5838 44.2323 15.5804 43.3876 14.3353C42.2067 12.5942 40.4825 11.1002 39.6967 9.1477C39.6334 8.99072 39.3801 8.87387 39.2204 9.00767C38.4623 9.64095 37.9547 10.4642 37.2492 11.1394C36.5035 11.853 35.6606 12.4952 34.9007 13.1972C34.1327 13.9071 33.396 14.3397 32.4282 15.2576C31.8261 15.8284 31.3204 16.3422 31.299 16.9594C31.299 16.9656 31.299 16.9728 31.299 16.9799C31.2883 17.2368 31.0055 17.3875 30.7531 17.2867C29.788 16.9005 28.6356 17.9878 28.5018 18.8833C28.4269 19.3855 28.5375 19.9002 28.723 20.3738C29.0593 21.2345 29.5472 21.9927 30.4668 22.2558C30.6934 22.3209 30.9601 22.3111 31.051 22.5689C31.0591 22.5903 31.0671 22.6135 31.076 22.6375C31.241 23.0817 31.4417 23.3877 31.5024 23.4822C32.0812 24.3849 32.4425 25.5034 33.3897 26.6094C33.8089 27.0991 34.1577 27.5745 35.2798 28.1873C35.3199 28.2096 35.8283 28.4727 35.8747 28.4798C37.4124 28.7296 38.9314 28.8651 40.4103 28.2595C40.9098 28.0553 41.3807 27.7832 41.8097 27.4559H41.8088Z"
      fill="#E58366"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.7219 21.1448C36.5515 21.795 37.7342 22.6138 37.7342 22.6138C37.5906 21.5417 37.9064 20.3394 37.9064 20.3394C37.9064 20.3394 36.8726 20.5722 36.7227 21.1448H36.7219Z"
      fill="#FFA475"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.4028 17.1586C40.8738 17.1809 41.6373 17.0766 42.2884 17.1229C42.9386 17.1693 43.2633 17.5689 43.5041 17.5234C43.7449 17.4779 42.8869 16.8188 41.8995 16.685C40.9121 16.5521 40.0032 16.7635 40.0032 16.7635C40.0032 16.7635 39.8855 17.1336 40.4028 17.1586Z"
      fill="#1F2933"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.4031 16.3973C36.4031 16.3973 35.5308 16.1181 34.4988 16.1752C33.4668 16.2323 32.4437 16.8209 32.6774 16.8852C32.9111 16.9494 33.3321 16.5766 34.0028 16.5801C34.6736 16.5837 35.4237 16.7451 35.9063 16.7594C36.437 16.7746 36.4031 16.3973 36.4031 16.3973Z"
      fill="#1F2933"
    />
    <path
      d="M37.755 24.3728C37.6201 24.3728 36.9971 24.3685 36.2327 24.0624C35.7467 23.8676 35.2629 23.6278 35.2629 23.6278C34.7276 23.3601 34.5457 23.2253 34.5328 23.2831C34.505 23.4008 35.1323 24.3707 35.9287 24.7304C36.5303 25.0023 37.1405 25.1393 37.7699 25.1414C38.5621 25.1436 39.1123 24.8524 39.4934 24.6704C39.5384 24.649 39.7739 24.5334 40.0651 24.3C40.2085 24.1844 40.6046 23.8483 40.8508 23.1546C40.8829 23.0625 40.9 23.0047 40.885 22.9705C40.8208 22.8163 40.262 23.4329 39.5598 23.8483C38.7377 24.3364 38.0183 24.3728 37.7571 24.375L37.755 24.3728Z"
      fill="#C96545"
    />
    <mask
      id="mask0_654_345"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x={23}
      y={33}
      width={30}
      height={32}
    >
      <path
        d="M52.001 45.104C52.116 45.9505 52.1829 46.7889 52.1829 47.597C52.1829 49.7113 52.0343 53.2732 51.8874 56.2786C51.7232 59.6365 49.9058 62.7365 46.6907 63.7189C42.6767 64.9455 36.5167 65.829 29.4028 63.4122C26.401 62.3923 24.5804 59.4728 24.3295 56.3124L23.7118 48.5317C23.6561 47.8304 23.6586 47.1258 23.7191 46.4249L24.7323 34.699C26.2032 34.4689 27.2253 33.2657 29.9824 33.2559C32.1855 34.9372 34.9773 35.4947 37.5211 35.533C40.0355 35.5705 42.1165 34.8801 43.9271 33.7339C44.7798 33.7839 47.7384 33.9596 48.6179 34.0524"
        fill="#0266DA"
      />
    </mask>
    <g mask="url(#mask0_654_345)">
      <path
        d="M53.2253 32.8503C43.729 34.593 28.9556 33.5347 22.845 32.8086V35.457L22.9737 37.7851C35.6403 40.9198 51.0362 39.1619 53.1917 37.8816L53.2253 35.5703V32.8503Z"
        fill="#FFCD30"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.0094 19.31C44.0094 19.31 43.1727 18.6968 42.8978 18.7412C42.6237 18.7857 42.4448 19.4439 41.5121 19.4462C40.5793 19.4485 40.383 19.2356 40.1535 19.0754C39.9239 18.916 39.8511 19.1469 39.9639 19.3024C40.061 19.4356 40.3881 19.7684 40.9974 19.9427C43.1373 20.4627 42.6194 18.9579 44.0094 19.31Z"
      fill="#1F2933"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.1733 19.5823C35.729 19.3192 35.9672 18.9455 36.0287 18.8001C36.1001 18.6306 35.9707 18.4156 35.7852 18.6065C35.5997 18.7974 35.4605 19.0356 34.5418 19.1738C33.624 19.3121 33.2824 18.6948 33.0014 18.693C32.7205 18.6913 32.0515 19.4173 32.0515 19.4173C33.3297 18.8634 33.1995 20.4136 35.1733 19.5814V19.5823Z"
      fill="#1F2933"
    />
    <path
      d="M46.6264 27.642C45.4151 27.3397 44.6784 26.1123 44.9807 24.902C45.2831 23.6907 46.5104 22.9539 47.7208 23.2563C48.9321 23.5587 49.6688 24.786 49.3656 25.9964C49.0632 27.2077 47.8359 27.9444 46.6255 27.642H46.6264Z"
      fill="#343434"
    />
    <path
      d="M48.0517 31.269C46.8404 30.9666 46.1046 29.7393 46.4069 28.5289C46.7093 27.3176 47.9366 26.5809 49.147 26.8833C50.3583 27.1856 51.095 28.413 50.7918 29.6233C50.4894 30.8346 49.2621 31.5714 48.0517 31.269Z"
      fill="#343434"
    />
    <path
      d="M48.4529 34.916C47.2417 34.6136 46.5058 33.3863 46.8082 32.1759C47.1106 30.9646 48.3379 30.2279 49.5483 30.5302C50.7595 30.8326 51.4954 32.0599 51.193 33.2703C50.8906 34.4816 49.6633 35.2183 48.4529 34.9151V34.916Z"
      fill="#343434"
    />
    <path
      d="M48.316 38.6601C47.1048 38.3577 46.368 37.1304 46.6713 35.92C46.9736 34.7088 48.2001 33.972 49.4113 34.2744C50.6226 34.5768 51.3585 35.8041 51.0561 37.0153C50.7537 38.2266 49.5264 38.9625 48.316 38.6601Z"
      fill="#343434"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.3983 37.7471C48.3983 37.7471 50.6371 36.452 51.984 37.4117C53.3308 38.3706 53.2238 39.6291 52.0732 39.7023C50.9225 39.7754 48.3903 38.524 48.3903 38.524C48.3903 38.524 51.8377 40.8645 51.0224 42.4789C50.2072 44.0924 48.5758 42.9686 48.2699 41.5905C47.9639 40.2125 47.8052 38.6694 47.8052 38.6694L48.3992 37.7471H48.3983Z"
      fill="white"
    />
    <path
      d="M27.4098 25.9478C26.8175 24.8489 27.2287 23.478 28.3276 22.8866C29.4265 22.2944 30.7974 22.7056 31.3896 23.8044C31.9819 24.9033 31.5707 26.2743 30.4718 26.8665C29.3729 27.4588 28.002 27.0476 27.4107 25.9487L27.4098 25.9478Z"
      fill="#343434"
    />
    <path
      d="M26.9218 29.8139C26.3295 28.715 26.7407 27.3441 27.8396 26.7519C28.9385 26.1596 30.3094 26.5708 30.9017 27.6697C31.4939 28.7686 31.0827 30.1395 29.9839 30.7309C28.885 31.3231 27.514 30.9119 26.9218 29.813V29.8139Z"
      fill="#343434"
    />
    <path
      d="M27.4375 33.4453C26.8453 32.3464 27.2565 30.9755 28.3554 30.3832C29.4542 29.791 30.8252 30.2021 31.4174 31.301C32.0097 32.3999 31.5985 33.7708 30.4996 34.3622C29.4007 34.9545 28.0298 34.5433 27.4375 33.4444V33.4453Z"
      fill="#343434"
    />
    <path
      d="M28.492 37.042C27.8997 35.9431 28.3109 34.5722 29.4098 33.9799C30.5087 33.3876 31.8796 33.7988 32.4719 34.8977C33.0641 35.9966 32.6529 37.3675 31.554 37.9598C30.4552 38.552 29.0842 38.1408 28.492 37.042Z"
      fill="#343434"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.8069 37.674C31.8069 37.674 32.0335 39.2091 32.0772 40.6193C32.1209 42.0294 30.8178 43.5217 29.6288 42.1588C28.4407 40.7959 31.2031 37.6767 31.2031 37.6767C31.2031 37.6767 29.058 39.5141 27.9243 39.7273C26.7906 39.9405 26.3776 38.7471 27.4453 37.4858C28.5139 36.2237 31.0024 36.9266 31.0024 36.9266L31.8052 37.6731L31.8069 37.674Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.6551 12.4591C67.6863 12.5419 67.8161 12.6006 67.9663 12.6006C68.1164 12.6006 68.2456 12.5419 68.2775 12.4591C68.4066 12.1233 68.5589 11.7259 68.7011 11.3552C69.0043 10.5633 69.8596 9.87285 71.0746 9.43896C71.311 9.35435 71.541 9.27242 71.7419 9.20045C71.8942 9.14595 71.9864 9.05172 71.9864 8.95024C71.9864 8.84877 71.8942 8.75424 71.7419 8.70004C71.3959 8.57628 70.9658 8.4227 70.5574 8.27666C69.6731 7.9611 69.0508 7.45886 68.8302 6.88254C68.6561 6.42847 68.4465 5.88225 68.2775 5.44143C68.2456 5.35863 68.1164 5.2999 67.9663 5.2999C67.8161 5.2999 67.6863 5.35863 67.6551 5.44143C67.526 5.77717 67.3737 6.17463 67.2315 6.5453C66.9283 7.33721 66.073 8.02764 64.858 8.46153C64.6208 8.54614 64.3916 8.62807 64.1907 8.70004C64.0384 8.75424 63.9462 8.84877 63.9462 8.95024C63.9462 9.05172 64.0384 9.14595 64.1907 9.20045C64.5367 9.32421 64.9669 9.47779 65.3752 9.62383C66.2595 9.93939 66.8818 10.4416 67.1024 11.0179C67.2764 11.472 67.4861 12.0182 67.6551 12.4591Z"
      fill="#FFA52F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.89001 60.8001C8.9116 60.7428 9.00147 60.7021 9.10539 60.7021C9.20932 60.7021 9.29868 60.7428 9.32077 60.8001C9.41014 61.0325 9.51556 61.3076 9.61396 61.5641C9.82381 62.1122 10.4157 62.59 11.2567 62.8903C11.4203 62.9489 11.5795 63.0056 11.7185 63.0554C11.824 63.0931 11.8877 63.1584 11.8877 63.2286C11.8877 63.2988 11.824 63.3642 11.7185 63.4018C11.4791 63.4874 11.1814 63.5937 10.8987 63.6948C10.2867 63.9132 9.85596 64.2608 9.70334 64.6597C9.58285 64.9739 9.43775 65.352 9.32077 65.6571C9.29868 65.7144 9.20932 65.755 9.10539 65.755C9.00147 65.755 8.9116 65.7144 8.89001 65.6571C8.80065 65.4247 8.69523 65.1496 8.59683 64.8931C8.38697 64.345 7.79505 63.8671 6.95412 63.5668C6.78995 63.5083 6.63132 63.4516 6.49225 63.4018C6.38682 63.3642 6.32306 63.2988 6.32306 63.2286C6.32306 63.1584 6.38682 63.0931 6.49225 63.0554C6.73173 62.9698 7.02945 62.8635 7.3121 62.7624C7.92409 62.544 8.35483 62.1964 8.50745 61.7975C8.62794 61.4832 8.77304 61.1052 8.89001 60.8001Z"
      fill="#0266DA"
    />
  </svg>

}

export default GirlMedidationPose;