import GirlMedidationPose from "@/components/SVG/GirlMedidationPose";
import FaCheck from "@/components/SVG/Fontawesome/FaCheck";

const LoginBenefitItem = (props: {
  text: string;
}) => {
  return <div className="flex items-center gap-2">
    <FaCheck className="w-4 h-4 fill-white bg-fill-success-bold p-1 rounded-full" />
    <span className="text-xs font-semibold">
      {props.text}
    </span>
  </div>
}

const LoginBenefits = (props: {
  items?: string[];
}) => {
  const items = props.items ?? ["Ask Unlimited doubts", "Get video solutions in multi languages", "Get video lecture by experts"];

  return <div className="p-4">
    <div className="flex justify-between">
      <div className=" py-0 ">
        <h5 className="text-xl font-bold">
          Clear your doubts with Unlimited solutions!
        </h5>
        <p className="mt-2 mb-0 text-xs text-text-inactive">
          Login to explore our key benefits
        </p>
      </div>

      <div>
        <GirlMedidationPose />
      </div>
    </div>

    <div className="mt-4 card !bg-body-bg space-y-4">
      {
        items.map((item, index) => <LoginBenefitItem key={index.toString()} text={item} />)
      }
    </div>
  </div>
}

export default LoginBenefits;