import { LOGIN_SCREENS } from "@/config/enums";
import { selectLoginIntentPayloadScreen } from "@/store/slices/offCanvas";
import { useSelector } from "react-redux";

const LoginTitle = (props: {
  excludeTitles?: string[]
}) => {
  const loginScreen = useSelector(selectLoginIntentPayloadScreen);
  const title = LOGIN_SCREENS[loginScreen as keyof typeof LOGIN_SCREENS];
  const excludeTitle = props.excludeTitles || [];

  if (excludeTitle.includes(title)) return <></>;

  return <h5 className="text-xl font-bold pb-0 p-4">
    {title}
  </h5>
}

export default LoginTitle;